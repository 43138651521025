<template>
  <StartImage parent="projects"></StartImage>
  <section class="inner-projects text-start section-margin">
    <div class="container">
      <h5>
        <span> Our Projects </span>
      </h5>
      <h3 class="section-title-margin">
        Feel free to browse all your projects and more
      </h3>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project1.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project2.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project3.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project4.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project5.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project6.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project7.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project8.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project9.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project10.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project11.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project12.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project12.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project13.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project14.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project15.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project16.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project17.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project20.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project21.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project18.jpg" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project19.jpg" class="card-img" />
          </div>
        </div>
      </div>

      <div class="text-center mb-5">
        <router-link to="/contact-us" class="btn btn-beig mt-4"
          >Contact Us</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import StartImage from "../components/StartImage.vue";

export default {
  components: {
    StartImage,
  },
};
</script>

<style scoped>
.inner-projects h4 {
  margin-bottom: 20px;
}

.box-shadow {
  box-shadow: rgba(255, 227, 194, 0.4) 5px 5px,
    rgba(255, 227, 194, 0.3) 10px 10px, rgba(255, 227, 194, 0.2) 15px 15px,
    rgba(255, 227, 194, 0.1) 20px 20px, rgba(255, 227, 194, 0.05) 25px 25px;
}

.inner-projects h5 span {
  border-bottom: 3px solid #ffe3c2;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.inner-projects h5 {
  margin-bottom: 25px;
}
</style>
